



























import axios from 'axios';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { baConfig } from '@/../config';
import { BaseVue } from '@/BaseVue';
import Chart from '@/components/Chart.vue';
import UiCard from '@/components/ui/UiCard.vue';
import UiDatePreset from '@/components/ui/UiDatePreset.vue';
import { CancelablePromise, CanceledError, makeCancelable } from '@/utils/CancelablePromise';
import { getEndpointUrl } from '@/utils/endpointUrlUtil';

type FiatCurrency = { name: string; symbol: string };

@Component({
  components: { Chart, UiCard, UiDatePreset },
})
export default class CashFlow extends BaseVue {
  @Prop({ required: true })
  currentFiat!: FiatCurrency;

  svcUrl = baConfig.txnsSvcURL || '';
  isLoadingCashFlow = 0;
  chartData: any = null;
  cashFlowNetTotal = 0;
  cashflowSelectedRange?: string | null = null;
  cashflowSelectedDates: Date[] = [];
  previousPromise: CancelablePromise<any> | null = null;

  private readonly chartOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month',
          round: 'month',
          tooltipFormat: 'MMMM YYYY',
          displayFormats: {
            month: 'MMM',
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        type: 'logarithmic',
        grid: {
          display: false,
        },
        ticks: {
          autoSkipPadding: 24,
          callback: (value: any) => {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
          },
        },
      },
    },
    responsive: true,
    barThickness: 20,
    plugins: {
      legend: {
        display: true,
        labels: {
          boxHeight: 12,
          boxWidth: 19,
          padding: 18,
        },
      },
      tooltip: {
        callbacks: {
          label: (ctx: any) => {
            return ctx.raw.y.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
          },
        },
      },
    },
  };

  mounted() {
    // settings this range triggers a value change that calls callCashFlowEndpoint()
    this.cashflowSelectedRange = 'LAST_6_MONTHS';
  }

  @Watch('$store.state.currentOrg.id')
  async onOrgIdUpdated() {
    this.chartData = null;
    this.cashFlowNetTotal = 0;

    if (this.cashflowSelectedRange === 'LAST_6_MONTHS') {
      this.callCashFlowEndpoint(this.cashflowSelectedDates);
    } else {
      this.cashflowSelectedRange = 'LAST_6_MONTHS';
    }
  }

  onPresetChange(range: string) {
    this.cashflowSelectedRange = range;
    window.pendo?.track('Dashboard - Change cashflow date range', { range });
  }

  public async callCashFlowEndpoint(range: Date[]) {
    this.isLoadingCashFlow++;

    const [from, to] = range;
    const orgId = this.$store.state.currentOrg.id;
    const query = { from: from.toISOString(), to: to.toISOString() };
    const endpointUrl = getEndpointUrl(
      this.svcUrl,
      ['orgs', orgId, 'transactions', 'dashboards', 'cashflow_fmv'],
      query
    );

    this.cashflowSelectedDates = [...range];

    try {
      this.previousPromise?.cancel();

      const originalPromise = axios.get(endpointUrl, {
        withCredentials: true,
      });
      const cancelablePromise = makeCancelable(originalPromise);
      this.previousPromise = cancelablePromise;
      const response = await cancelablePromise;

      if (response.status === 200) {
        this._handleCashFlowData(response.data);
      } else {
        this.showErrorSnackbar('Failed fetching cash flow data!');
      }
    } catch (error) {
      if (error instanceof CanceledError) {
        console.info('The cashflow promise was canceled');
      } else {
        console.error(error);
        this.showErrorSnackbar('Failed fetching cash flow data!');
      }
    } finally {
      this.isLoadingCashFlow--;
    }
  }

  private _handleCashFlowData(data: any) {
    const mapToAxes = (record: any) => {
      return {
        x: record.date,
        y: Number(record.value) || 0,
      };
    };

    const datasets = [
      {
        label: 'Inflow',
        data: data?.inflow ? data.inflow.map(mapToAxes) : [],
        backgroundColor: '#3AC08F',
      },
      {
        label: 'Outflow',
        data: data?.outflow ? data.outflow.map(mapToAxes) : [],
        backgroundColor: '#00A7F8',
      },
    ];

    this.chartData = {
      type: 'bar',
      data: { datasets },
      options: this.chartOptions,
    };

    this.cashFlowNetTotal = Number(data?.netTotal) || 0;
  }
}
