




















import axios from 'axios';
import moment from 'moment';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { baConfig } from '@/../config';
import { BaseVue } from '@/BaseVue';
import Chart from '@/components/Chart.vue';
import UiButtonToggle from '@/components/ui/UiButtonToggle.vue';
import UiCard from '@/components/ui/UiCard.vue';
import { CancelablePromise, CanceledError, makeCancelable } from '@/utils/CancelablePromise';
import { getEndpointUrl, stringifyParams } from '@/utils/endpointUrlUtil';

@Component({
  components: { Chart, UiCard, UiButtonToggle },
})
export default class CategoriationSummary extends BaseVue {
  svcUrl = baConfig.txnsSvcURL || '';
  isLoading = 0;
  chartData: any = null;
  selectedStackingOption = 'count';
  previousPromise: CancelablePromise<any> | null = null;

  readonly stackingOptions = [
    { id: 'count', value: 'Count' },
    { id: 'fmv', value: 'FMV' },
  ];

  private readonly chartOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        stacked: true,
        time: {
          unit: 'month',
          round: 'month',
          tooltipFormat: 'MMMM YYYY',
          displayFormats: {
            month: 'MMM',
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        stacked: true,
        type: 'logarithmic',
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    barThickness: 20,
    plugins: {
      legend: {
        display: true,
        labels: {
          boxHeight: 12,
          boxWidth: 19,
          padding: 18,
        },
      },
    },
    onClick: this._onBarClick,
    onHover: (event: any, bar: any) => {
      const el = event.chart.canvas;
      if (el) el.style.cursor = bar?.length ? 'pointer' : 'default';
    },
  };

  private readonly yAxisTickConfig = {
    autoSkipPadding: 24,
  };

  mounted() {
    this.callEndpoint();
  }

  @Watch('$store.state.currentOrg.id')
  onOrgIdUpdated() {
    this.chartData = null;
    this.selectedStackingOption = 'count';
    this.callEndpoint();
  }

  public onStackingOptionChange(stacking: string) {
    if (stacking === this.selectedStackingOption) return;
    this.selectedStackingOption = stacking;
    window.pendo?.track('Dashboard - Switched categorization chart stacking (FMV/Count)', { stacking });
    this.callEndpoint();
  }

  public async callEndpoint() {
    this.isLoading++;

    const orgId = this.$store.state.currentOrg.id;
    const method = `categorized_${this.selectedStackingOption}`;
    const endAt = moment().startOf('month');
    const startFrom = moment(endAt).subtract(1, 'year');
    const query = {
      from: startFrom.toDate().toISOString(),
      to: endAt.toDate().toISOString(),
    };
    const endpointUrl = getEndpointUrl(this.svcUrl, ['orgs', orgId, 'transactions', 'dashboards', method], query);

    try {
      this.previousPromise?.cancel();

      const originalPromise = axios.get(endpointUrl, {
        withCredentials: true,
      });
      const cancelablePromise = makeCancelable(originalPromise);
      this.previousPromise = cancelablePromise;
      const response = await cancelablePromise;

      if (response.status === 200) {
        this._handleCatData(this._produceFinalDatasets({ ...response.data }));
      } else {
        this.showErrorSnackbar(`Failed fetching categorization ${this.selectedStackingOption} data!`);
      }
    } catch (error) {
      if (error instanceof CanceledError) {
        console.info(`The categorized ${this.selectedStackingOption} promise was canceled`);
      } else {
        console.error(error);
        this.showErrorSnackbar(`Failed fetching categorization ${this.selectedStackingOption} data!`);
      }
    } finally {
      this.isLoading--;
    }
  }

  private _handleCatData(data: any) {
    const mapToAxes = (record: any) => {
      return {
        x: record.date,
        y: Number(record.value) || 0,
      };
    };

    const datasetVisualsMapping = {
      categorized: {
        label: 'Categorized',
        color: '#3AC08F',
      },
      uncategorized: {
        label: 'Uncategorized',
        color: '#00A7F8',
      },
      categorizedInflow: {
        label: 'Categorized Inflow',
        color: '#3AC08F',
      },
      categorizedOutflow: {
        label: 'Categorized Outflow',
        color: '#C4E4CF',
      },
      uncategorizedInflow: {
        label: 'Uncategorized Inflow',
        color: '#00A7F8',
      },
      uncategorizedOutflow: {
        label: 'Uncategorized Outflow',
        color: '#A2D2E8',
      },
    };

    if (!data) {
      this.chartData = null;
      return;
    }

    const datasets = Object.keys(data).map((datasetName) => {
      const datasetKey = datasetName as keyof typeof datasetVisualsMapping;
      return {
        label: datasetVisualsMapping[datasetKey].label,
        data: data[datasetKey] ? data[datasetKey].map(mapToAxes) : [],
        backgroundColor: datasetVisualsMapping[datasetKey].color,
      };
    });

    const options: any = { ...this.chartOptions };

    if (this.selectedStackingOption === 'fmv') {
      options.plugins.tooltip = {
        callbacks: {
          label: (tooltip: any) => {
            return tooltip.raw.y.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
          },
        },
      };

      options.scales.y.ticks = {
        ...this.yAxisTickConfig,
        callback: (value: any) => {
          return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
      };
    } else {
      delete options.plugins.tooltip;
      options.scales.y.ticks = this.yAxisTickConfig;
    }

    this.chartData = {
      type: 'bar',
      data: { datasets },
      options,
    };
  }

  private _produceFinalDatasets(data: any) {
    if (this.selectedStackingOption !== 'fmv') return data;

    try {
      const mergedData = [...data.categorizedInflow, ...data.categorizedOutflow].reduce((acc, { date, value }) => {
        const numericalValue = Number(value) || 0;
        if (!acc[date]) {
          acc[date] = { date, value: numericalValue };
        } else {
          acc[date].value += numericalValue;
        }
        return acc;
      }, {});

      delete data.categorizedInflow;
      delete data.categorizedOutflow;

      return { categorized: Object.values(mergedData), ...data };
    } catch (error) {
      console.error(error);
      return data;
    }
  }

  private _onBarClick(event: any, clickedElements: any) {
    if (!clickedElements?.length) return;

    const { raw, dataset } = clickedElements[0].element.$context;
    const isUncat = dataset.label.toLowerCase().includes('uncategorized');
    const path = '/transactions';
    const query = {
      txFilter: {
        categorizationFilter: isUncat ? 'Uncategorized' : 'Categorized',
        pivotDate: raw.x,
      },
    };
    window.pendo?.track('Dashboard - Click categorization chart', query.txFilter);
    this.$router.push({ path: `${path}?${stringifyParams(query)}` });
  }
}
